<template>
  <tr
    class='border-t'
    :class='selectedStyle'
    @click='customFieldTemplateDetailsOpen(template)'>
    <td class='fa fa-align-justify handle'>
      <view-list-icon class='inline-block' style='margin-top: -2px;'></view-list-icon>
    </td>
    <td class='whitespace-no-wrap'>{{template.custom_field_name}}</td>
    <td>{{template.field_type}}</td>
    <td>{{recurringString}}</td>
    <td>{{template.template_type}}</td>
    <td>
      <div v-for='rule in template.rules.all'
            :key='`rule-${rule.custom_field_template_id}-${rule.custom_field_value}`'>
        {{customFieldTemplateName(rule.custom_field_template_id)}}  {{rule.operator}} {{displayValue(rule)}}
      </div>
    </td>
    <td><span v-if='template.required'>required</span></td>
  </tr>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ViewListIcon } from '@vue-hero-icons/outline'

export default {
  name: 'CustomFieldTableRows',
  components: {
    ViewListIcon,
  },
  props: {
    template: Object,
  },
  computed: {
    ...mapState('customFields', [
      'customFieldTemplates'
    ]),
    ...mapState('users', [
      'users',
    ]),
    ...mapState('funds', [
      'funds',
    ]),
    ...mapGetters('customFields', [
      'editingCustomFieldId',
    ]),
    selectedStyle () {
      return (this.editingCustomFieldId === this.template.id) ? 'bg-blue-100 text-gray-900 font-semibold' : ''
    },
    recurringString () {
      return (this.template.recurring) ? 'Recurring' : ''
    },
  },
  methods: {
    ...mapActions('customFields', [
      'customFieldTemplateDetailsOpen',
    ]),
    customFieldTemplateName (templateId) {
      let found = this.customFieldTemplates.find((template) => template.id === templateId)
      return found ? found.custom_field_name : null
    },
    displayValue (rule) {
      if (rule.custom_field_type === 'user') {
        let found = this.users.find((user) => user.id === rule.custom_field_value)
        if (found) {
          return found.name
        } else {
          return '해당 사용자 없음'
        }
      } else if (rule.custom_field_type === 'fund') {
        let found = this.funds.find((fund) => fund.id === rule.custom_field_value)
        if (found) {
          return found.short_name
        } else {
          return '해당 펀드 없음'
        }
      } else if (rule.custom_field_values) {
        return rule.custom_field_values.toString()
      } else {
        return rule.custom_field_value
      }
    },
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-left uppercase tracking-wide;
  }
  th, td {
    @apply py-2 pl-2 pr-6;
  }
</style>
