<template>
  <div class='pb-8 px-2 lg:px-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>Fields</h1>
      </div>
    </portal>
    <div class='flex flex-row gap-x-2 my-6'>
      <button
        class='border p-3 rounded-md border-gray-200 text-xs text-gray-700 opacity-80 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md flex flex-row justify-center items-center'
        @click='newCustomField'>
        <plus-circle-icon class='h-4'/>
        Add
      </button>
      <button v-show='reorderedTemplates'
        class='border p-3 rounded-md border-gray-200 text-xs text-gray-700 opacity-80 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        @click='updateOrdering'>
        현재 Field 순서 적용
      </button>
      <button v-show='reorderedTemplates'
        class='border p-3 rounded-md border-gray-200 text-xs text-gray-700 opacity-80 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        @click='reloadTable'>
        Sort 취소
      </button>
    </div>
    <div class='overflow-scroll'>
      <table class='text-sm'>
        <thead>
          <tr>
            <th></th>
            <th class='whitespace-no-wrap'>Field 명</th>
            <th class='whitespace-no-wrap'>input type</th>
            <th class='whitespace-no-wrap'>recurring</th>
            <th class='whitespace-no-wrap'>Data On</th>
            <th class='whitespace-no-wrap'>Rules</th>
            <th class='whitespace-no-wrap'>Required</th>
          </tr>
        </thead>
        <draggable 
          v-model='templates'
          tag='tbody'
          :animation='100'
          @end='reordered'
          ghost-class='ghost'>
          <custom-field-table-rows
            v-for='template in templates'
            :key='`customFieldTemplate-${template.id}`'
            :template='template' />
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
import draggable                from 'vuedraggable'
import { mapActions, mapState } from 'vuex'
import { PlusCircleIcon }       from '@vue-hero-icons/outline'
import CustomFieldTableRows     from '@/views/custom_fields/CustomFieldTableRows.vue'

export default {
  name: 'CustomFieldTemplates',
  components: {
    draggable,
    PlusCircleIcon,
    CustomFieldTableRows,
  },
  data () {
    return {
      templates: [],
      reorderedTemplates: false,
    }
  },
  watch: {
    customFieldTemplates: {
      handler: function (newVal) {
        let tmp = JSON.parse(JSON.stringify(newVal))
        this.templates = tmp.sort((a,b) => a.sort_index - b.sort_index)
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('customFields',[
      'customFieldTemplates',
    ]),
    isOrderingChange () {
      return this.templates.some((template, index) => template.sort_index !== index+1)
    },
  },
  methods: {
    ...mapActions('customFields', [
      'getCustomFieldTemplates',
    ]),
    ...mapActions([
      'sidepanelClose',
    ]),
    ...mapActions('customFields', [
      'editExistingTemplate',
      'newCustomField',
      'updateCustomFieldOrdering'
    ]),
    updateOrdering () {
      this.updateCustomFieldOrdering(this.templates).then(() => {
        this.reorderedTemplates = false
      })
    },
    reloadTable () {
      this.getCustomFieldTemplates().then(() => {
        this.reorderedTemplates = false
      })
    },
    doneEditing () {
      this.sidepanelClose()
    },
    reordered () {
      this.reorderedTemplates = true

    },
  },
  mounted () {
    this.getCustomFieldTemplates()
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-left uppercase tracking-wide;
  }
  th, td {
    @apply py-2 pl-2 pr-6;
  }
  .ghost {
    opacity: 0.8;
    background: #c8ebfb;
  }
  .sortable-chosen {
    @apply text-gray-800;
    opacity:0.1;
  }
</style>
